import React from "react";

import {
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { RiskRatingEnum } from "@/apis/services/HazardService";
import { CONSEQUENCE_TYPES } from "@/components/high-risks/types";
import { AssetPopUp } from "@/components/map/AssetPopUp/AssetPopUp";
import type { AllHazardTypes } from "@/components/map/UploadRasterPopup";
import type { IAssetsWithRiskLevels } from "@/components/map/utils/mapUtils/IAssetsWithRiskLevels";
import { getRiskRatingColorsByLevel } from "@/components/risk-ratings/ClassOneRiskRatings";
import { AREA_DISPLAY_LABEL } from "@/components/shared/user/user-settings";
import { getHazardDisplayLabel } from "@/utils/display-label-utils";
import { getAreaValue } from "@/utils/measurement/area-utils";

import styles from "./AssetPopUp.module.scss";

export interface GroupPopUpProps {
  asset: IAssetsWithRiskLevels;
  groupId?: string;
  selectedHazard?: AllHazardTypes | "";
  consequence?: string;
}

export const GroupAssetPopUp = (props: GroupPopUpProps) => {
  const { asset, consequence, selectedHazard, groupId } = props;
  const { t } = useTranslation();

  const dynamicColour = getRiskRatingColorsByLevel(
    asset.risk || RiskRatingEnum.NotAssessed
  ).color;

  const from = groupId ? `?from=groups&groupId=${groupId}` : "?from=/";
  const href = `/assets/${asset.id}${from}`;

  return (
    <AssetPopUp {...props}>
      <Card style={{ width: consequence === "" ? 250 : 300 }}>
        <Link href={href} underline="none">
          <CardContent className={styles.cardContentContainer}>
            <Typography
              component="div"
              className={styles.cardTitle}
              variant="h5"
            >
              {asset.name}
            </Typography>
            <Divider />
            <Typography
              component="div"
              style={{
                color: dynamicColour,
              }}
              className={styles.riskName}
            >
              {t(asset.risk)}
            </Typography>
            <Box className={styles.cardDetailContainer}>
              <Box
                style={{
                  width: consequence === "" || !asset.hazard ? "50%" : "33%",
                }}
              >
                <Typography
                  gutterBottom
                  component="div"
                  variant="caption"
                  className={styles.detailheader}
                >
                  {selectedHazard === "" ? "Top Hazard" : "Hazard"}
                </Typography>
                <Typography
                  gutterBottom
                  component="div"
                  className={styles.detailInfo}
                >
                  {asset.hazard
                    ? getHazardDisplayLabel(asset.hazard)
                    : CONSEQUENCE_TYPES[asset.hazard] ||
                      asset.hazard ||
                      "Not assessed"}
                </Typography>
              </Box>
              <Box
                style={{
                  width: consequence === "" || !asset.hazard ? "50%" : "33%",
                }}
              >
                <Typography
                  gutterBottom
                  component="div"
                  variant="caption"
                  className={styles.detailheader}
                >
                  {t("occ_area")}
                </Typography>
                <Typography
                  gutterBottom
                  component="div"
                  className={styles.detailInfo}
                >
                  {`${getAreaValue(
                    asset.occ_area || 0,
                    0
                  )} ${AREA_DISPLAY_LABEL}`}
                </Typography>
              </Box>
              {consequence !== "" && asset.hazard && (
                <Box style={{ width: "33%" }}>
                  <Typography
                    gutterBottom
                    component="div"
                    variant="caption"
                    className={styles.detailheader}
                  >
                    Consequence
                  </Typography>
                  <Typography
                    gutterBottom
                    component="div"
                    className={styles.detailInfo}
                  >
                    {CONSEQUENCE_TYPES[asset.consequence]}
                  </Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </Link>
      </Card>
    </AssetPopUp>
  );
};
